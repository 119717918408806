/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

const handleAttributesOptionsChange = function handleAttributesOptionsChange() {
  let code = document.querySelector('[data-js-product-code]').innerHTML;

  $('#sylius-variant-attributes-table').empty();

  $('#sylius-variants-attributes > div.sylius-variants-attributes-set').each(function () {
    let divCode = $(this).data('js-attributes-code');
    if (divCode === code) {
      $(this).children('div').each(function () {
        let name = $(this).data('name');
        let value = $(this).text().trim();
        let newRow = `<tr><td data-js-variant-attribute-name="${name}">${name}</td><td data-js-variant-attribute-value="${value}">${value}</td></tr>`;
        $('#sylius-variant-attributes-table').append(newRow);
      });
    }
  });

  $('#sylius-variant-attributes-table').removeClass('d-none');

};


const SyliusVariantsAttributes = () => {
  const syliusVariantsAttributes =
    document.getElementById('sylius-variants-attributes') || null;

  if (syliusVariantsAttributes) {
    handleAttributesOptionsChange();
  }

};

export default SyliusVariantsAttributes;